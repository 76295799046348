@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-arrows::-webkit-inner-spin-button,
    .no-arrows::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
#root {
    height: 100%;
}

html {
    @apply text-gray-900;
    --scrollbarBG: hsl(
        210,
        30%,
        97%
    ); /* Copied from gray-50 in tailwind.config.js. */
    --thumbBG: hsl(
        210,
        8%,
        47%
    ); /* Copied from gray-500 in tailwind.config.js. */
}
*::-webkit-scrollbar {
    width: 14px;
}
* {
    scrollbar-width: auto;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 0 solid var(--scrollbarBG);
    min-height: 22px;
}

input[type='file']::file-selector-button {
    @apply -ml-3 mr-3 -mt-2 -mb-2 py-2 px-3;
    @apply bg-gray-50;
    @apply text-gray-600;
    @apply border border-y-0 border-r border-l-0 border-gray-100;
    @apply cursor-pointer;
}

input[type='file']::file-selector-button:hover {
    @apply bg-gray-100;
}

.react-datepicker {
    font-family: 'Roboto', ui-sans-serif, system-ui, -apple-system, sans-serif !important;
}
